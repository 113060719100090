<style lang="less">
@import "./resources.less";
</style>

<template>
  <div id="resources">
      <div slot="right" class="p-right-container">
        <div class="left-body">
          <div class="layout-nav">
            <div class="content-padding title-row-inline">
              <span>{{ $t('x_menu_resources') }} ({{ layers.total }})</span>
            </div>
            <div class="top-btns-panel">
              <Button
                type="primary"
                @click="createNewLayer"
              >
                {{ $t("x_create_new_resource") }}
              </Button>
            </div>
          </div>
          <div class="layout-body">
            <div class="body-content">
              <div>
                <Table
                  v-if="layers.list.length>0"
                  class="resources-table"
                  context-menu
                  ref="layerTable"
                  :data="layers.list"
                  :columns="layerColumns"
                  :highlight-row="true"
                  :row-class-name="rowClassName"
                  :loading="showLoading"
                  @on-select="selectRows"
                  @on-select-cancel="cancelSelectRows"
                  @on-select-all="selectAllRows"
                  @on-select-all-cancel="selectAllRows"
                  @on-row-click="onLayerClicked"
                >
                  <template slot-scope="{ row }" slot="title">
                    <img :src="typeIcon(row)" alt class="layericon" />
                    <span style="margin-left: 5px">{{row.title}}</span>
                  </template>
                  <template slot-scope="{ row }" slot="sharinglevel">
                    <span>{{
                      row.isPrivate ? $t("x_private") : $t("x_public")
                    }}</span>
                  </template>
                  <template slot-scope="{ row }" slot="size">
                    {{bytesToSize((row.size)*1024*1024)}}
                  </template>
                </Table>
                <br />

                <Page
                  v-if="layers.list.length>0"
                  transfer
                  class="flr"
                  :total="layers.total"
                  :current="layers.page"
                  :page-size="layers.pageSize"
                  :page-size-opts="pageSize"
                  @on-page-size-change="layerPageSizeChange"
                  @on-change="layerPageChange"
                  show-sizer
                />

              </div>
            </div>
          </div>
        </div>
      </div>
    <Modal
      v-model="layers.addLayerModal"
      width="800"
      :mask-closable="false"
      class="model-limit-height resource-modal"
      @on-visible-change="addLayerModalChange"
    >
      <div slot="close" :class="{ ptevtnone: !modalLoading }">
        <Button type="text" v-show="modalLoading">
          <Spin>
            <Icon type="ios-loading" size="18" class="spin-icon-load"></Icon>
          </Spin>
        </Button>
        <Button type="text" v-show="!modalLoading">
          <i class="fas fa-times"></i>
        </Button>
      </div>
      <div slot="header">{{ $t("x_create_new_resource") }}</div>

      <div>
        <div class="publish_sp1" v-if="publishStep == 0">
          <RadioGroup v-model="publishType" vertical>
            <Radio :label="2">{{ $t("x_data_publish") }}</Radio>
          </RadioGroup>
        </div>
        <div
          class="publish_sp2"
          v-if="publishStep == 1 || publishStep == 2 || publishStep == 3"
        >
          <Row type="flex">
            <Col span="24">
              <div>
                <br />
                <Form
                  label-position="left"
                  ref="dataLayerForm"
                  :model="dataLayerForm"
                  :label-width="150"
                >
                  <FormItem
                      label="Fly Plan"
                      v-if="!stepAfterProcess"
                  >
                    <Select
                        transfer
                        v-model="dataLayerForm.flyPlans"
                        @on-change="flyPlanDataChanged"
                    >
                      <Option
                          v-for="(item, idx) in flyPlanDataList"
                          :value="item.flyPlanId"
                          :key="idx"
                      >{{ item.name }}</Option
                      >
                    </Select>
                  </FormItem>

                  <FormItem
                    :label="$t('x_project_publish_resource_name')"
                    v-if="dataLayerForm.type"
                  >
                    <Input v-model="dataLayerForm.name" placeholder />
                  </FormItem>

                  <FormItem :label="$t('x_capture_date')">
                    <DatePicker
                      type="date"
                      v-model="dataLayerForm.date"
                    ></DatePicker>
                  </FormItem>

                  <FormItem
                    :label="$t('x_select_data_folder')"
                    v-if="!stepAfterProcess && layerTypeCag != 0"
                  >
                    <Row type="flex" justify="start">
                      <Col span="24">
                        <Breadcrumb separator=">">
                          <BreadcrumbItem>
                            <span @click="toPath">{{ $t("x_home") }}</span>
                          </BreadcrumbItem>
                          <BreadcrumbItem
                            v-for="(k, idx) in storage.paths"
                            :key="idx"
                          >
                            <span @click="toPath(k, idx)" v-if="idx<storage.paths.length-1">{{ k }}</span>
                            <span v-if="idx==storage.paths.length-1">{{ k }}</span>
                          </BreadcrumbItem>
                        </Breadcrumb>
                      </Col>
                    </Row>
                    <Row type="flex" justify="start">
                      <Col span="24" class="small-row shrink-table-header">
                        <Table
                          context-menu
                          size="small"
                          ref="storageTable"
                          :data="storage.list"
                          :columns="fileColumns"
                          :highlight-row="true"
                          @on-row-dblclick="openFolderRow"
                          @on-row-click="selectFolderRow"
                          :row-class-name="rowClassName"
                          max-height="300"
                        >
                          <template slot-scope="{ row }" slot="thumbnail">
                            <i
                              :class="{
                                'fas fa-folder': row.type == 'folder',
                                'fas fa-file-contract': row.type != 'folder',
                                fileicon: true,
                              }"
                            ></i>
                          </template>
                        </Table>
                      </Col>
                    </Row>

                    <Alert type="success">
                      <b>{{ $t("x_choosed") }}:</b>
                      <span v-if="selectRowInfo.type">{{ selectRowInfo.type=='folder'?'/'+selectRowInfo.filename.replace(rootPath, ''):'/'+selectRowInfo.filename.replace(rootPath, '') }}</span>
                    </Alert>
                  </FormItem>

                </Form>
              </div>
            </Col>
          </Row>
        </div>
        <div class="publish_sp3" v-if="publishStep == 4">
          <Row type="flex">
            <Col span="24">
              <div v-if="publishStep == 4">
                <h3>{{ $t("x_img_info_table") }}</h3>
                <br />
                <Table
                  ref="EOTable"
                  :columns="EOColumns"
                  :data="EOData"
                  max-height="300"
                ></Table>
                <br />
                <Page
                  v-if="EOPageTotal > EOPageSize"
                  transfer
                  class="flr"
                  :total="EOPageTotal"
                  :current="EOPageCurrent"
                  :page-size="EOPageSize"
                  @on-change="EOPageChange"
                />
                <br />
                <div class="eo-sum-op">
                  <Row type="flex">
                    <Col span="24">
                      {{ EOSummary.totalImgCount }}
                      {{ $t("x_eo_img_total_count") }};
                      <br />
                      {{ EOSummary.positionedCount }}
                      {{ $t("x_eo_img_positioned") }};
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div slot="footer">
        <Button
          type="text"
          v-if="publishStep == 0"
          @click="layers.addLayerModal = false"
          >{{ $t("x_cancel") }}</Button
        >
        <Button
          type="primary"
          v-if="publishStep == 0"
          :disabled="!publishType"
          @click="publishNextStep"
          >{{ $t("x_next") }}</Button
        >
        <Button
          type="text"
          v-if="
            publishStep == 1 ||
            publishStep == 2 ||
            publishStep == 3
          "
          @click="resetPageData"
          style="float: left"
        >
          <i class="fas fa-angle-double-left"></i>
          {{ $t("x_back") }}
        </Button>



        <Button
          type="primary"
          v-if="
            dataLayerForm.type != 1 &&
            dataLayerForm.type != 14 &&
            dataLayerForm.type != 17 &&
            dataLayerForm.type != 18 &&
            !stepAfterProcess &&
            (publishStep == 2 || publishStep == 6)
          "
          :disabled="requiredCredit > remainingCredit"
          @click="submitDataLayer"
          :loading="modalLoading"
          :title="msgNoSubmit"
        >
          <span v-if="!modalLoading">{{ $t("x_submit") }}</span>
          <span v-else>{{ loadingmsg }}</span>
        </Button>

        <Button
          type="primary"
          v-if="
            dataLayerForm.type == 14 &&
            !stepAfterProcess &&
            (publishStep == 2 || publishStep == 6)
          "
          :disabled="(requiredCredit > remainingCredit) && !dataLayerForm.x && !dataLayerForm.y"
          @click="submitDataLayer"
          :loading="modalLoading"
          :title="msgNoSubmit"
        >
          <span v-if="!modalLoading">{{ $t("x_submit") }}</span>
          <span v-else>{{ loadingmsg }}</span>
        </Button>

        <Button
          type="primary"
          v-if="
            (dataLayerForm.type == 1 ||
              dataLayerForm.type == 17 ||
              dataLayerForm.type == 18) &&
            !stepAfterProcess &&
            (publishStep == 2 || publishStep == 6)
          "
          :disabled="requiredCredit > remainingCredit"
          @click="publishVerifyEO"
          :loading="modalLoading"
          :title="msgNoSubmit"
        >
          <span v-if="!modalLoading">{{ $t("x_next") }}</span>
          <span v-else>{{ loadingmsg }}</span>
        </Button>


        <Button
          type="text"
          v-if="publishStep == 4"
          @click="jumpToStep(2)"
          style="margin-right: 15px"
        >
          <i class="fas fa-angle-double-left"></i>
          {{ $t("x_back") }}
        </Button>

        <Button
          type="primary"
          style="float: right; margin-right: 8px"
          v-if="
            publishStep == 4 &&
            (dataLayerForm.type == 1 ||
              dataLayerForm.type == 17 ||
              dataLayerForm.type == 18)
          "
          :disabled="
            publishStep == 4 &&
            (layerTypeCag == 0 || modalLoading || fslelectFolder == '')
          "
          @click="submitDataLayer"
          :loading="modalLoading"
        >
          <span v-if="!modalLoading">{{ $t("x_publish_resource") }}</span>
          <span v-else>{{ loadingmsg }}</span>
        </Button>

        <Upload
          ref="eoupload"
          action="//"
          :before-upload="clickUploadEOCSV"
          :max-size="2048 * 1000"
          v-if="publishStep == 4 && !modalLoading"
          style="float: right; margin-right: 8px"
        >
          <Button type="primary">
            <span>
              <i class="fas fa-upload"></i>
              {{ $t("x_upload_eo_csv") }}
            </span>
          </Button>
        </Upload>

        <Button
          type="primary"
          style="float: right; margin-right: 8px"
          v-if="
            publishStep == 4 &&
            (dataLayerForm.type == 1 ||
              dataLayerForm.type == 17 ||
              dataLayerForm.type == 18)
          "
          :disabled="
            publishStep == 4 &&
            (layerTypeCag == 0 || modalLoading || fslelectFolder == '')
          "
          @click="clickDownloadEOCSV"
          :loading="modalLoading"
        >
          <span>
            <i class="fas fa-download"></i>
            {{ $t("x_download_eo") }}
          </span>
        </Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import _ from "lodash";
import Service from "../../libs/service.js";
import dateFormat from "dateformat";

export default {
  components: {},
  data() {
    return {
      flyPlans:[],
      selectedFlyPlan:null,
      pageSize: [10, 25, 50],
      minVal: "248px",
      leftBarShrink: false,
      splitVal: "248px",
      publishType: null,
      publishStep: 0,
      isCompany: false,
      fileColumns: [
        {
          title: "-",
          slot: "thumbnail",
          className: "folder-info-column",
          width: "112",
          align: "left",
        },
        {
          title: this.$t("x_name"),
          key: "name",
        },
      ],
      storage: {
        list: [],
        keywords: "",
        path: "/" + Cookies.get("user"),
        paths: [],
        newFolderName: "",
        newfolder: false,
      },
      users: {
        keywords: "",
        total: 0,
        page: 1,
        pageSize: 50,
        list: [],
        originresult: [],
        checked: [],
        selected: {},
        addUserModal: false,
        allList: [],
        allOriginresult: [],
        dropdownKeywords: "",
      },
      userColumns: [
        {
          title: "-",
          slot: "avatar",
          width: "48",
          className: "folder-info-column",
          align: "center",
        },
        {
          title: this.$t("x_username"),
          key: "username",
          sortable: true,
        },
        {
          title: this.$t("x_signup_email"),
          key: "email",
          sortable: true,
          className: "folder-info-column",
        },
        {
          title: this.$t("x_table_header_share_with"),
          slot: "inteam",
          className: "folder-info-column",
          align: "right",
        },
      ],
      placement: false,
      displayMode: "list",
      layers: {
        keywords: "",
        total: 0,
        page: 1,
        pageSize: 50,
        list: [],
        originresult: [],
        checked: [],
        selected: {},
        clicked: {},
        addLayerModal: false,
        updateLayerModal: false,
        cloneLayerModal: false,
        title: "",
        order: null,
        category: null,
        sidx: null,
        force: false,
        detailLayerModal: false,
        addToProjectModal: false,
      },
      showLoading: false,
      modalLoading: false,
      layerColumns: [
        {
          title: this.$t("x_name"),
          key: "title",
          slot: "title",
          className: "cell-no-left-padding",
          align: "left",
          ellipsis: true,
          resizable: true
        },
        {
          title: this.$t("x_owner"),
          key: "username",
          className: "folder-info-column",
        },
        {
          title: this.$t("x_date"),
          key: "displaydate",
          className: "folder-info-column",
          sortable: true,
        },
        {
          title: this.$t("x_type"),
          key: "category",
          className: "folder-info-column",
        },
        {
          title: this.$t("x_size_resource"),
          slot: "size",
          className: "folder-info-column",
        }
      ],
      layerEditForm: {
        title: "",
        description: "",
        billboard: undefined,
      },
      layerRules: {
        title: [
          {
            required: true,
            message: this.$t("x_empty_err"),
            trigger: "blur",
          },
        ],
      },
      stepAfterProcess: false,
      dataPublishErrorStr: "",
      serviceLayerForm: {
        type: "SUPERMAP",
        date: new Date(),
        ogcLayerName: "0",
        tileMatrixList: undefined,
        url_example: "",
        isPrivate: true,
        isQueryIncluded: false,
        script4d: "",
      },
      ogcLayerList: [],
      tileMatrixList: [],
      ogcCheckTimeout: undefined,
      dataLayerForm: {
        epsg: 2326,
        date: new Date(),
        isPrivate: true,
        eoFormat: undefined,
        isSpatialOffset: false,
        is3DTiles: true,
        xOffset: 0,
        yOffset: 0,
        zOffset: 0,
      },
      objUploadColumns: [
        {
          title: this.$t("x_name"),
          slot: "name",
        },
        {
          title: `${this.$t("x_upload_lng")} / X`,
          slot: "lng",
        },
        {
          title: `${this.$t("x_upload_lat")} / Y`,
          slot: "lat",
        },
        {
          title: this.$t("x_upload_height"),
          slot: "h",
        },
        {
          title: this.$t("x_data_source"),
          slot: "source",
        },
        {
          title: this.$t("x_site_mission_corsys"),
          slot: "EPSG",
        },
      ],
      objUploadData: [],
      objSourceList: [
        {
          k: 0,
          v: "Pix4D",
        },
        {
          k: 1,
          v: "Others",
        },
      ],
      objcorsysList: [
        {
          k: "4326",
          v: "WGS84",
        },
        {
          k: "2326",
          v: "HK1980 GRID",
        },
        {
          k: "3826",
          v: "TWD97 / TM2 zone 121",
        },
      ],
      corsysList: [],
      layerTypeServiceList: [
        {
          v: this.$t("x_arcgis_server_map"),
          k: "21",
          h: 21,
          type: "",
        },
        {
          v: this.$t("x_supermap_3d"),
          k: "23",
          h: 23,
          type: "S3M",
        },
        {
          v: this.$t("x_url_template"),
          k: "26",
          h: 26,
          type: "URLTEMPLATE",
        },
        {
          v: this.$t("x_wms_layer"),
          k: "24",
          h: 24,
          type: "",
        },
        {
          v: this.$t("x_wmts_layer"),
          k: "25",
          h: 25,
          type: "",
        },
      ],
      eoFormats: [
        {
          id: 1,
          title: "Orbit",
        },
        {
          id: 2,
          title: "OSlam",
        },
      ],
      initGisResourceForm: {
        name: "",
        type: "",
        isPrivate: true,
      },
      gisTypeList: [
        {
          title: this.$t("x_shp_point_2d"),
          type: "Point",
        },
        {
          title: this.$t("x_shp_linestring_2d"),
          type: "Line String",
        },
        {
          title: this.$t("x_shp_polygono_2d"),
          type: "Polygon",
        },
        {
          title: this.$t("x_shp_point_3d"),
          type: "3D Point",
        },
        {
          title: this.$t("x_shp_linestring_3d"),
          type: "3D Line String",
        },
        {
          title: this.$t("x_shp_polygon_3d"),
          type: "3D Polygon",
        },
      ],
      flyPlanDataList:[],
      keyedLayerTypeServiceList: [],
      layerType: null,
      layerTypeCag: null,
      loadingmsg: "",
      fslelectFolder: "",
      roleId: null,
      defaultEPSG: undefined,
      EOColumns: [
        {
          title: this.$t("x_eo_ph_name"),
          key: "img_name",
          width: 130,
          sortable: true,
          fixed: "left",
        },
        {
          title: 'Order',
          key: "order",
          width: 170,
        },
        {
          title: this.$t("x_date"),
          key: "date",
          width: 170,
          sortable: true,
        },
        {
          title: this.$t("x_easting"),
          key: "lng",
          width: 120,
        },
        {
          title: this.$t("x_northing"),
          key: "lat",
          width: 120,
        },
        {
          title: this.$t("x_ms_height"),
          key: "alt",
          width: 120,
        },
        {
          title: this.$t("x_phi"),
          key: "omega",
          width: 100,
        },
        {
          title: this.$t("x_omega"),
          key: "phi",
          width: 100,
        },
        {
          title: this.$t("x_kappa"),
          key: "kappa",
          width: 100,
        },
      ],
      EOData: [],
      EOSummary: {
        totalImgCount: 0,
        positionedCount: 0,
      },
      EOPageTotal: 1,
      EOPageSize: 500,
      EOPageCurrent: 1,

      // ---------------- Credit calculator related ------------------

      // ------------ End of credit calculator related ---------------
      creditCalReq: undefined,
      creditSelectFolder: "", // Duplicate of fslelectFolder to deal with double click issue for folders
      isShowingCredit: false,
      isCreditUnderCal: false, /// Not sure whether useful
      creditAlertType: "info",
      requiredCredit: Infinity,
      remainingCredit: 0,
      msgNoSubmit: this.$t("x_folder_no_type_data"),
      downloadableResourceCategories: [
        "SHAPEFILE",
        "POINT CLOUD",
        "OBJECT",
        "MEDIA",
        "PANORAMA",
        "VIDEO",
        "DEM",
        "GEOTIFF",
        "TRAJECTORY",
        "3D TILES",
        "KML",
      ],

      // ---------------- Uploading S3M related ------------------
      isUploadingS3M: false,
      uploadS3MProgress: 0,
      s3mUploadFormat: ["zip"],
      // ------------- End of Uploading S3M related --------------

      emptyString: "",
      iotLayerForm: {
        name: "",
        isPrivate: true,
        categoryId: undefined,
        iconUrl: undefined,
      },
      iotCategoryList: [],

      /***Ray test for revit 20210121***/
      revitDataCols: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "Layer Name",
          key: "name",
        },
      ],
      revitLayerData: [],
      selectLayerData: [],
      /***Ray test end***/

      // ----------------------------------- Resource Preview Map --------------------------------------
      mapListRatio: 0.5,
      selectedLayersById: {},
      // ------------------------------- End of Resource Preview Map -----------------------------------

      currentLeftType: 'all',
      chooseCreater: [],
      chooseCreaterName: [],
      chooseType: [],
      chooseTypeName: [],
      createrDownVisible: false,
      typeDownVisible: false,
      dropdownItemsVisible: true,
      mapDrawer: false,

      rootPath: '',
      selectRowInfo: {},

      choosePublicResource: false,
      selectRowList: [],

      currentChoose: '',
      projectColumns: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: 'Title',
          key: 'title'
        }
      ],
      projectData: [],
      projectKeywords: '',

      xyFieldList: [],
      selectProjectData: [],
    };
  },
  computed: {
  },
  methods: {
    init() {
      Window.vui = this;
      this.roleId = Cookies.get("access");
      this.currentLeftType = this.$route.query.resy;
      if (this.gup("isCompany")) {
        this.isCompany = Boolean(this.gup("isCompany"));
      }
      this.keyedLayerTypeServiceList = _.keyBy(this.layerTypeServiceList, "k");
      this.storage.path = "/";
      this.storage.paths = [];
      this.getLayerList();
      this.getCreatorList();
      this.getDefaultEPSG();
      let that = this;
      this.layerTypeDataList = [];
      // Service.requestBuffer(
      //   "get",
      //   `/sys/pricing/publishes/${Cookies.get("companyId")}`,
      //   "",
      //   function (typeList) {
      //     for (let i = 0; i < typeList.length; i++) {
      //       let typeItem = typeList[i];
      //       that.layerTypeDataList.push({
      //         v: that.$t(typeItem.title),
      //         k: typeItem.datatypeKey,
      //         type: typeItem.typeAlias,
      //       });
      //     }
      //
      //     that.keyedLayerTypeDataList = _.keyBy(that.layerTypeDataList, "k");
      //   },
      //   function (err) {
      //     console.warn(err);
      //   }
      // );


      Service.request(
          "get",
          `/sys/flyplan/list`,
          null,
          function(dt){
            that.flyPlanDataList = _.filter(_.sortBy(dt.data,['name']),function(o) { return o.flyPlanId > 148; })
            if(dt.data.length>0) {
              that.dataLayerForm.flyPlans = dt.data[0].flyPlanId
            }
            console.log(that.flyPlanDataList)
          },
          function (err){
            console.error(err)
          }
      )
    },
    gup(name, url) {
      if (!url) url = location.href;

      name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
      var regexS = "[\\?&]" + name + "=([^&#]*)";
      var regex = new RegExp(regexS);
      var results = regex.exec(url);
      return results == null ? NaN : results[1];
    },
    rowClassName(row, index) {
        return "folder-row";
    },
    bytesToSize (bytes) {
      if (bytes === 0) return "0 B";
      var k = 1024, // or 1000
        sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        i = Math.floor(Math.log(bytes) / Math.log(k));
      return (bytes / Math.pow(k, i)).toFixed(2) + " " + sizes[i];
    },
    createNewLayer() {
      this.storage.path = "/";
      // this.loadData();
      this.layers.addLayerModal = true;
    },
    photourl(p) {
      if (!p.previewUrl || p.previewUrl == "") {
        return `https://via.placeholder.com/500x360/F0F0F0/999999/?text=Layer`;
      } else {
        return `${window.Baseurl}${p.previewUrl}?token=${Cookies.get(
          "sdms_token"
        )}`;
      }
    },
    publishNextStep() {
      this.publishStep = this.publishType;
      this.dataLayerForm.type = 1;
      this.layerTypeDataChanged(1);

      console.log(this.publishStep);
    },
    getLayerList() {
      var that = this;
      that.showLoading = true;
      if (this.layers.force) {
        this.layers.page = 1;
        this.layers.pageSize = 50;
      }
      var data = {
        page: this.layers.page,
        limit: this.layers.pageSize,
        sidx: this.layers.sidx ? this.layers.sidx : "layer_id",
        order: this.layers.order ? this.layers.order : "desc",
      };

      Service.request(
        "get",
        "/sys/layer/page",
        data,
        function (dt) {
          that.layers.force = false;

          that.layers.total = dt.page.totalCount;
          that.layers.page = dt.page.currPage;

          dt.page.list.forEach(function (f) {
            f.displaydate = dateFormat(f.createTime, "yyyy-mm-dd");
          });

          for(let i=0; i<dt.page.list.length; i++) {
            let layerItem = dt.page.list[i];
            let layerId = layerItem.layerId;

            if(that.selectedLayersById[layerId]) {
              dt.page.list[i]._checked = true;
            }
            else {
              dt.page.list[i]._checked = false;
            }
          }

          that.layers.list = that.layers.originresult = dt.page.list;
          that.showLoading = false;

          that.$nextTick(() => {
            that.setChecked();
          });
        },
        function (err) {
          that.showLoading = false;
        }
      );
    },
    layerPageSizeChange(num) {
      this.layers.page = 1;
      this.layers.pageSize = num;
      this.getLayerList();
    },
    layerPageChange(page) {
      this.layers.page = page;
      this.getLayerList();
    },
    loadData: function () {
      var filetype = "media";
      var path = this.storage.path || "/";
      var vpath = path == "/" ? "" : path;
      var that = this;
      Service.request(
        "get",
        "/oss/list",
        { path: path, type: filetype}, //, type: filetype
        function (dt) {
          that.modalLoading = false;
          dt.data.forEach(item => {
            if(item.type=='folder') {
              item.name = item.filename.substring((item.filename.substring(0,item.filename.length-1)).lastIndexOf("/")+1, item.filename.length-1);
            } else if(item.type=='file') {
              item.name = item.filename.substring(item.filename.lastIndexOf("/")+1, item.filename.length);
            }
            item.filename2 = item.filename.split(dt.root).join("");
          });

          that.storage.list = dt.data;
          that.rootPath = dt.root;
        },
        function (err) {
          that.modalLoading = false;
        }
      );
    },
    toPath(name, idx) {
      this.selectRowInfo = {};
      if (!idx && idx != 0) {
        this.storage.path = "/";
        this.storage.paths = [];
        this.loadData();
      } else {
        let a = _.dropRight(
          this.storage.paths,
          this.storage.paths.length - (idx + 1)
        );
        let b = a.join("/");
        this.storage.path = this.rootPath+`${b}/`;
        this.storage.paths = (this.storage.path.replace(this.rootPath, "").substring(0, this.storage.path.replace(this.rootPath, "").length-1)).split("/");
        this.loadData();
      }
    },
    openFolderRow(row, idx) {
      if (row.type == "folder" && !this.modalLoading) {
        this.openFolder(row);
      }
    },
    openFolder(row) {
      if (!this.modalLoading) {
        this.storage.path = row.filename;
        this.storage.paths = (this.storage.path.replace(this.rootPath, "").substring(0, this.storage.path.replace(this.rootPath, "").length-1)).split("/");
      }

      this.modalLoading = true;
      this.loadData();
    },
    selectFolderRow(row, idx) {
      console.log('*****', row)
      this.selectRowInfo = row;
      this.fslelectFolder = row.filename;
      if (!this.modalLoading) {
        this.storage.path = row.filename;
        // this.fslelectFolder = `${
        //   this.storage.path == "/" ? "" : this.storage.path
        // }/${row.name}`;
        if(row.type=='file') {
          this.refreshCredit(true);
        }else if(row.type=='folder') {
          this.refreshCredit();
        }
      }
    },

    getUserList() {
      var that = this;

      let data = {
        page: 1,
        limit: 99999,
        sidx: "user_id",
        order: "desc",
      };

      Service.request(
        "get",
        "/sys/user/page",
        data,
        function (dt) {
          let a = dt.page.list;

          _.each(a, function (k) {
            k.selected = false;
            if (_.includes(that.layerEditForm.userIds, k.userId)) {
              k.selected = true;
            }
          });

          that.users.list = that.users.originresult = a;
        },
        function (err) {}
      );
    },
    resetPageData() {
      if (this.$refs["cloneLayerForm"]) {
        this.$refs["cloneLayerForm"].resetFields();
      }
      if (this.$refs["dataLayerForm"]) {
        this.$refs["dataLayerForm"].resetFields();
      }
      if (this.$refs["serviceLayerForm"]) {
        this.$refs["serviceLayerForm"].resetFields();
      }
      if (this.$refs["initGisResourceForm"]) {
        this.$refs["initGisResourceForm"].resetFields();
      }
      this.stepAfterProcess = false;
      this.dataPublishErrorStr = "";
      this.objUploadData = [];
      this.loadingmsg = this.$t("x_loading");
      this.showLoading = false;
      this.modalLoading = false;
      this.serviceLayerForm = {
        type: "SUPERMAP",
        ogcLayerName: "0",
        date: new Date(),
        tileMatrixList: undefined,
        url_example: "",
        isPrivate: true,
        isQueryIncluded: false,
        script4d: "",
      };
      this.dataLayerForm = {
        epsg: Number(this.defaultEPSG),
        date: new Date(),
        isSpatialOffset: false,
        is3DTiles: true,
        xOffset: 0,
        yOffset: 0,
        zOffset: 0,
      };
      this.initGisResourceForm = {
        name: "",
        type: "Point",
        isPrivate: true,
      };
      this.layerType = null;
      this.layerTypeCag = null;
      this.fslelectFolder = "";
      this.storage.path = "/";
      this.storage.paths = [];
      this.storage.list = [];
      this.layers.title = "";
      this.layers.selected = {};
      this.publishStep = 0;
      this.publishType = null;

      this.dataLayerForm.isPrivate = true;
      this.serviceLayerForm.isPrivate = true;

      // Reset credit info and cancel current credit request if any
      if (this.creditCalReq) {
        this.creditCalReq.abort();
        this.creditCalReq = undefined;
        this.isShowingCredit = false;

        this.finishCalculatingCredit();
      }

      this.creditSelectFolder = "";
      this.isShowingCredit = false;
      this.requiredCredit = Infinity;
      this.remainingCredit = 0;
      this.msgNoSubmit = this.$t("x_folder_no_type_data");
    },
    jumpToStep(step) {
      if (this.isPmsForAi) {
        step = 6;
        this.isPmsForAi = undefined;
        delete this.isPmsForAi;
      }
      this.publishStep = step;
    },
    flyPlanDataChanged(d){console.log(d)},
    layerTypeDataChanged(d) {
      // Get the detailed info of the selected option
      let layerItem;


      if (window.hideUnsupportInfo) {
        window.hideUnsupportInfo();
        delete window.hideUnsupportInfo;
      }


      try {
        this.layerType = d;

        console.log(d);
        // Only register the eoFormat parameter when datatype is 'pano'



          if (d == "1") {
            this.corsysList = [
              {
                srid: 4326,
                srtext: "WGS 84 (4326)",
                proj4text: "+proj=longlat +datum=WGS84 +no_defs ",
              },
            ];
            this.dataLayerForm.epsg = 4326;
          }


        this.selectRowInfo = {};
        this.requiredCredit = Infinity;
        this.remainingCredit = 0;
        this.isShowingCredit = false;
        this.fslelectFolder = "";
        this.storage.path = "/";
        this.storage.paths = [];

        this.loadData();
        this.refreshCredit(true);

      } catch (e) {}
    },
    submitDataLayer() {
      this.dataPublishErrorStr = "";

      if (!this.layerType) {
        return;
      }

      var data = {
        path: this.fslelectFolder,
        epsg: this.dataLayerForm.epsg,
        isPrivate: this.dataLayerForm.isPrivate,
        title: this.dataLayerForm.name,
        collectDate: dateFormat(this.dataLayerForm.date, "yyyy-mm-dd HH:MM:ss"),
      };

      /// data.epsg settings has a lot of redundancy in the code

      var that = this;

      this.$nextTick(() => {
        that.loadingmsg = that.$t("x_processing");
        that.modalLoading = true;
      });

      if (this.layerType == "0" || this.layerType == "1") {
        // publish media layer
        // Default WGS84 if not specified
        /// Need to notify the user?
        data.epsg = data.epsg ? data.epsg + "" : Cookies.get("srid") || "4326";
        data.isGenerateTrajectory = true,
        data.flyPlanId = that.dataLayerForm.flyPlans.toString()
        var flyPlanName = _.find(that.flyPlanDataList,function(x){return x.flyPlanId == that.dataLayerForm.flyPlans}).name
        // var flyPlanName = _find(that.flyPlanDataList,function(x){return x.flyPlanId == that.dataLayerForm.flyPlans}).district
        data.title = flyPlanName+"_"+data.title

        Service.request(
          "post",
      "/sys/layer/publish/photo",
          data,
          function (dt) {
            that.layers.addLayerModal = false;
            that.modalLoading = false;
            that.getLayerList();
            that.resetPageData();

            if (typeof window.checkTask === "function") window.checkTask();
          },
          function (err) {
            that.modalLoading = false;
          }
        );
      }
    },
    waitForEOResult(eoPath, eoCheckingMsg) {
      const truncateMid = function (fullStr, strLen, separator) {
        if (fullStr.length <= strLen) return fullStr;

        separator = separator || '...';

        var sepLen = separator.length,
            charsToShow = strLen - sepLen,
            frontChars = Math.ceil(charsToShow/2),
            backChars = Math.floor(charsToShow/2);

        return fullStr.substr(0, frontChars) +
            separator +
            fullStr.substr(fullStr.length - backChars);
      };
      console.log("Wait for EO Result called")
      let that = this;
      Service.request(
          "get",
          "/sys/validate/getEOResult",
          { path: eoPath }, function (dt) {
              if (dt.data.eo) {
                that.EOData = dt.data.eo;
                _.sortBy(that.EOData, function(item) {
                  return item.date;
                });

                var result = _.map(that.EOData, v => _.assign({}, v, {
                  order: _.indexOf(that.EOData, v)+1,
                  img_name: truncateMid(v.img_name.split("/").slice(3).join("/"), 40)
                }));
                console.log('Newvalue',result)

                that.EOData = result

                that.EOSummary.totalImgCount = dt.data.total;
                that.EOSummary.positionedCount = dt.data.positioned;
                that.EOPageCurrent = 1;
                that.EOPageTotal = that.EOSummary.totalImgCount;

                that.loadingmsg = "";
                that.modalLoading = false;

                that.publishStep = 4;
                if (eoCheckingMsg) eoCheckingMsg();
              } else {
                setTimeout(() => {
                  that.waitForEOResult(eoPath, eoCheckingMsg)
                }, 10000)
              }
          });
    },
    publishVerifyEO() {
      this.isPmsForAi = this.publishStep == 6;

      this.modalLoading = true;
      this.loadingmsg = this.$t("x_processing");
      let that = this;

      let eoCheckingMsg = this.$Message.loading({
        content: this.$t("x_eo_loading"),
        duration: 0,
      });

      let requestUrl;

      switch (this.dataLayerForm.type) {
        case 18: // video
          requestUrl = "/sys/validate/getVideosFilesInfo";
          break;
        default:
          // Others, supposing all following image
          requestUrl = "/sys/validate/getImageFilesInfo";
          break;
      }

      Service.request(
        "get",
        requestUrl,
        { path: this.fslelectFolder },
        function (dt) {
          const eoPath = dt.eoPath;
          that.waitForEOResult(eoPath, eoCheckingMsg);
        },
        function (err) {
          that.loadingmsg = "";
          that.modalLoading = false;

          if (eoCheckingMsg) eoCheckingMsg();
        }
      );
    },
    clickUploadEOCSV(file) {
      // console.log(file);
      let that = this;

      let formData = new FormData();
      formData.append("file", file);
      formData.append("path", this.fslelectFolder);

      if (this.dataLayerForm.eoFormat)
        formData.append("eoFormat", this.dataLayerForm.eoFormat);

      let eoCheckingMsg = this.$Message.loading({
        content: this.$t("x_eo_loading"),
        duration: 0,
      });

      /// Almost the same as returning eo from exif, conclude a generic function for this
      Service.requestFrom(
        "post",
        "/sys/validate/getImageFilesByEO",
        formData,
        function (dt) {
          const eoPath = dt.eoPath;
          that.waitForEOResult(eoPath, eoCheckingMsg);
        },
        function (err) {
          that.loadingmsg = "";
          that.modalLoading = false;

          if (eoCheckingMsg) eoCheckingMsg();
        }
      );

      return false;
    },
    clickDownloadEOCSV() {
      let reqObj = {
        path: this.fslelectFolder,
      };
      this.modalLoading = true;

      let that = this;
      Service.request(
        "get",
        "/sys/validate/EOCSV",
        reqObj,
        function (dt) {
          let csvContent = dt.data;
          console.log(dt.data)
          // string to file
          let csvBlob = new Blob([csvContent], { type: "text/csv" });
          let csvUrl = window.URL.createObjectURL(csvBlob);

          let doma = document.createElement("a");
          doma.href = csvUrl;
          doma.setAttribute("download", "EO.csv");
          doma.setAttribute("target", "_blank");

          doma.style.display = "none";
          doma.click();

          that.loadingmsg = "";
          that.modalLoading = false;
        },
        function (err) {
          that.loadingmsg = "";
          that.modalLoading = false;
        }
      );
    },
    addLayerModalChange(open) {
      if (!open) {
        this.resetPageData();
      }
    },
    getDefaultEPSG() {
      if (this.defaultEPSG) return;

      let that = this;
      Service.request(
        "get",
        `/sys/company/info/${Cookies.get("companyId")}`,
        "",
        function (dt) {
          that.defaultEPSG = dt.data.srid + "";
          that.dataLayerForm.epsg = dt.data.srid;
        },
        function (err) {}
      );
    },
    typeIcon(row) {
      var icon = "";
      switch (row.category) {

        case "MEDIA":
        case "PANORAMA":
          icon = "./images/Image_Picture_32.png";
          break;

        default:
          icon = "./images/MapLayerProperty_32.png";
          break;
      }

      return icon;
    },
    finishCalculatingCredit() {
      if (window.finishedCreditTimeout) {
        clearTimeout(window.finishedCreditTimeout);
        delete window.finishedCreditTimeout;
      }
      if (window.finishedCredit) {
        window.finishedCredit();
        delete window.finishedCredit;
      }
    },
    refreshCredit(isForceCheckSameFolder) {
      /// TODO: Calculate credits when selecting file/folder
      this.finishCalculatingCredit();

      if (!this.layerType) return;

      if (
        !isForceCheckSameFolder &&
        this.fslelectFolder == this.creditSelectFolder
      )
        return; // This means current folder is the same as that being looked at, skip

      /// TODO: Cannot publish root folder
      if (this.fslelectFolder.split("/").length == 2) {
        // No publish, and no credit notification
        this.requiredCredit = Infinity;
        this.remainingCredit = 0;
        return;
      }

      this.modalLoading = true;

      if (this.creditCalReq) {
        this.creditCalReq.abort();
        this.creditCalReq = undefined;
      }

      let that = this;
      window.finishedCreditTimeout = setTimeout(function () {
        window.finishedCredit = that.$Message.loading({
          content: that.$t("x_cal_pub_size"),
          duration: 0,
        });
        delete window.finishedCreditTimeout;
      }, 500);

      setTimeout(function () {
        // that.isShowingCredit = true;
        this.isShowingCredit = false;
        that.isCreditUnderCal = true;
        that.requiredCredit = Infinity;
        that.remainingCredit = 0;
        that.creditAlertType = "info";
        that.modalLoading = true;

        let fileTypeStr = 'media'


        that.creditSelectFolder = that.fslelectFolder;
        let reqObj = {
          layerType: fileTypeStr, // This need to be a map, or retrieved from this.layerTypeDataList
          path: that.fslelectFolder,
          // path: that.selectRowInfo.filename
        };

        /// Making a http request here that should be CANCELLABLE
        // var requestUrl = `/file/size?path=${reqObj.path}&type=${reqObj.layerType}`;
        var requestUrl;
        if(reqObj.path=='') {
          requestUrl = `/oss/size?type=${reqObj.layerType}`;
        } else {
          requestUrl = `/oss/size?path=${encodeURIComponent(reqObj.path)}&type=${reqObj.layerType}`;
        }
        var xmlHttp = new XMLHttpRequest();
        xmlHttp.onreadystatechange = function () {
          if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
            that.finishCalculatingCredit();
            that.modalLoading = false;
            creditCallback(xmlHttp.responseText);
          } else if (xmlHttp.status > 400) {
            that.finishCalculatingCredit();
            that.modalLoading = false;
            that.$Message.error(dt.msg); /// TODO: prepare reject function
          }
        };
        xmlHttp.open("GET", window.Baseurl + requestUrl, true); // true for asynchronous
        xmlHttp.setRequestHeader("token", Cookies.get("sdms_token"));
        xmlHttp.withCredentials = true;
        xmlHttp.send();
        that.creditCalReq = xmlHttp;

        function creditCallback(dt) {
          // console.log(dt);

          let resp = JSON.parse(dt);
          if (resp.code == 0) {
            if (!resp.data.size) {
              /// Really hide the credit ui?
              // Not valid publication, no publish
              that.isShowingCredit = false;
              that.msgNoSubmit = that.$t("x_folder_no_type_data");
            } else {
              // Credit enough, proceed to publish
              that.isShowingCredit = true;
              that.creditAlertType = "success";
              that.requiredCredit = parseFloat(resp.data.size.toFixed(4));
              that.remainingCredit = parseFloat(resp.data.remain.toFixed(4));
              that.msgNoSubmit = that.$t("x_submit");
            }
          } else {
            // Credit not enough, no publish
            that.isShowingCredit = true;
            that.creditAlertType = "error";
            that.$Message.error(resp.msg);
            that.requiredCredit = resp.data.size;
            that.remainingCredit = resp.data.remain;
            that.msgNoSubmit = resp.msg;
          }

          that.creditCalReq.abort();
          that.creditCalReq = undefined;
          that.isCreditUnderCal = false;
        }
      }, 100);
    },
    EOPageChange(page) {
      let reqObj = {
        path: this.fslelectFolder,
        page: page,
      };

      this.modalLoading = true;
      this.loadingmsg = this.$t("x_processing");
      let that = this;

      let eoCheckingMsg = this.$Message.loading({
        content: this.$t("x_eo_loading"),
        duration: 0,
      });

      Service.request(
        "get",
        "/sys/validate/getEOPage",
        reqObj,
        function (dt) {
          dt = dt.data;
          that.EOData = dt;

          that.loadingmsg = "";
          that.modalLoading = false;

          if (eoCheckingMsg) eoCheckingMsg();
        },
        function (err) {
          that.loadingmsg = "";
          that.modalLoading = false;

          if (eoCheckingMsg) eoCheckingMsg();
        }
      );
    },
    getCreatorList() {
      var that = this;
      Service.request(
        "get",
        "/sys/user/list",
        "",
        function (dt) {
          that.users.allList = that.users.allOriginresult = dt.data;
        },
        function (err) {
        }
      );
    },
    selectRows(selection, row) {
      this.selectRowList.push(row);
    },
    cancelSelectRows(selection, row) {
      this.selectRowList.forEach((item,i) => {
        if (item.layerId === row.layerId) {
          this.selectRowList.splice(i, 1)
        }
      })
    },
    selectAllRows(selection) {
      if (selection.length === 0) {
        let data = this.$refs.layerTable.data
        data.forEach((item) => {
          this.selectRowList.forEach((k, idx) => {
            if (item.layerId === k.layerId) {
              this.selectRowList.splice(idx, 1)
            }
          })
        })
      } else {
        selection.forEach(item => {
          this.selectRowList.push(item)
        })
      }
    },
    setChecked () {
      let objData;
      objData = this.$refs.layerTable.objData
      for (let index in objData) {
        this.selectRowList.forEach((item) => {
          if(objData[index].layerId === item.layerId) {
            objData[index]._isChecked = true
          }
        })
      }
    },
    onLayerClicked(row){
      this.layers.clicked = row;
      console.log(row);
      this.onLayerSelectionChange([row]);
    },
    onLayerSelectionChange (selection) {
      // 1. Check which layers within this page is selected, and which ones are not
      let layersStat = {};
      for(let i=0; i<selection.length; i++) {
        let selectedItem = selection[i];
        layersStat[selectedItem.layerId] = selectedItem;
      }

      // 2. Reg the opened layer list by adding the new layers and removing the closed ones
      for(let i=0; i<this.layers.list.length; i++) {
        let layerItem = this.layers.list[i];
        if(layersStat[layerItem.layerId] && !this.selectedLayersById[layerItem.layerId]) {
          let caseObj;
          if (layerItem.parameter)
            caseObj = JSON.parse(layerItem.parameter);

          // Reg into 'this.selectedLayersById'
          let layerOption = {
            id: layerItem.layerId,
            text: layerItem.displayTitle || layerItem.title,
            displaytitle: layerItem.displayTitle,
            k: layerItem.layerId,
            selected: true,
            type: layerItem.type,
            category: layerItem.category,
            opacity: 100,
            url: layerItem.url,
            geometry: layerItem.geometry,
            elevation: layerItem.elevation,
            case: layerItem.parameter,
            caseObj: caseObj,
            queryDataset: JSON.parse(layerItem.queryDataset),
            flatten_region: JSON.parse(layerItem.flattenRegion),
            structure: layerItem.structure,
            userId: layerItem.userId,
            billboard: layerItem.billboard
          };

          this.selectedLayersById[layerItem.layerId] = layerOption;
        }
        else if (!layersStat[layerItem.layerId] && this.selectedLayersById[layerItem.layerId]) {
          delete(this.selectedLayersById[layerItem.layerId]);
        }
      }

      let layerListArray = [];
      for(let layerId in this.selectedLayersById) {
        layerListArray.push(this.selectedLayersById[layerId]);
      }

      window.map.layerCtrl(layerListArray);
    },
  },
  watch: {
    $route(to) {
      this.currentLeftType = this.$route.query.resy;
      if(this.currentLeftType!=='map') {
        this.getLayerList()
      }
    }
  },
  mounted() {
    this.init()
  },
};
</script>